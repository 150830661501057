import logger from '../../logger/logger';
import { type SkipTeamRandomizerIcebreakerNotification } from '../../types';
import { DoubleRightArrow } from '../icons/Arrows';
import { XIcon } from '../icons/XIcon';
import { useNotificationDataSource } from '../Notification/Context';
import { type BaseNotificationProps } from '../Notification/type';
import { useTeamRandomizerAPI } from './Context';

const log = logger.scoped('teamrandomizer-skipicebreakernotif');

export const SkipTeamRandomizerIcebreakerNotificationView = (
  props: BaseNotificationProps & {
    notification: SkipTeamRandomizerIcebreakerNotification;
  }
): JSX.Element => {
  const nds = useNotificationDataSource();
  const { skipIcebreaker } = useTeamRandomizerAPI();

  const handleSkip = async () => {
    log.info('skip clicked', { id: props.notification.id });
    skipIcebreaker();
    nds.dismiss(props.notification.id);
  };

  const handleClose = () => {
    nds.dismiss(props.notification.id);
    if (props.closeToast) {
      props.closeToast();
    }
  };

  return (
    <div className='flex flex-row items-center gap-9 text-sm font-white p-5 rounded-xl bg-secondary'>
      <DoubleRightArrow />

      <div className='flex flex-col'>
        <div className='text-2xs text-white whitespace-nowrap flex flex-row'>
          Skip the Icebreaker Timer and start the Game?
        </div>
      </div>

      <button
        className='btn text-sms text-blue-004 cursor-pointer'
        onClick={handleSkip}
      >
        Skip
      </button>

      <div className='cursor-pointer' onClick={handleClose}>
        <XIcon className='w-2.5 h-2.5 fill-current stroke-current' />
      </div>
    </div>
  );
};
